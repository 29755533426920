@import url('https://use.typekit.net/ewj2ajr.css');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
img {
  margin: 0;
  padding: 0;
}

body {
  background-color: #F0F0EB;
}

html {
  width: 100%;
  height: 100%;
}

body,
code {
  font-size: 12px;
  font-family: 'big-caslon-fb', 'Pretendard Variable', sans-serif;
  font-weight: 400;
}

body {
  max-width: 100%;
  margin: 0 auto;
  min-height: 100%;
}

ul,
ol,
dl,
li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 400;
  font-size: 1em;
}

img,
fieldset {
  border: 0;
  vertical-align: top;
}

table {
  table-layout: fixed;
  width: 100%;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

table img {
  vertical-align: middle;
}

th,
td {
  border: 0;
  word-wrap: break-word;
}

input,
select,
textarea {
  vertical-align: middle;
}

label {
  cursor: pointer;
}

caption {
  overflow: hidden;
  width: 1px;
  font-size: 1px;
  line-height: 0;
  text-indent: 150%;
  white-space: nowrap;
}

legend,
.blind {
  display: block;
  overflow: hidden;
  position: absolute;
  font-size: 1px;
  line-height: 0;
  color: transparent;
  text-indent: -150%;
  white-space: nowrap;
}

hr.layout {
  display: none;
}

button {
  overflow: visible;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0 !important;
  cursor: pointer;
  background: transparent;
}

a {
  text-decoration: none;
  color: #000;
  transition: 0.3s ease;
}

a:hover {
  text-decoration: none;
}

p {
  margin-bottom: 0 !important;
}

input,
textarea,
select {
  font-family: 'Pretendard Variable', 'big-caslon-fb', sans-serif;
  max-width: 100%;
  padding: 10px 0;
  color: #000;
  vertical-align: middle;
  outline: 0;
  border-radius: 0;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='tel'],
textarea {
  width: 100%;
  font-size: var(--en-font3);
  font-weight: inherit;
  color: #353535;
  border: none;
  border-bottom: 1px solid #000;
}
input[type='text'],
input[type='password'],
input[type='number'],
input[type='tel'] {
  height: 50px;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='tel']:focus {
  border-color: #000;
  color: #000;
}

input[type='text'] {
  font-weight: inherit;
  color: #353535;
  border-radius: 0;
  outline-style: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type='file'] {
  overflow: hidden;
  max-width: 100%;
}

input[type='submit'] {
  background: #F0F0EB;
  border: none;
}

select {
  max-width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 20px 0 12px;
  font-size: 13px;
  line-height: 36px;
  color: #000;
  word-break: break-all;
  font-weight: inherit;
  background-size: 20px auto;
}

select:focus {
  border-color: #000;
}

select.disabled,
select:disabled,
textarea.disabled,
textarea:disabled {
  color: #bcbcbc;
}

textarea {
  resize: none;
  line-height: 1.2em;
}

textarea.disabled {
  background-color: #F0F0EB;
  color: #bfbfbf;
}

select {
  max-width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 20px 0 12px;
  border: 1px solid #dedfe2;
  border-radius: 0;
  font-size: 13px;
  line-height: 36px;
  color: #000;
  word-break: break-all;
  font-weight: inherit;
  cursor: pointer;
  background-size: 20px auto;
}

select:focus {
  border-color: #000;
}

select.disabled,
select:disabled {
  color: #bcbcbc;
}

sub,
sup {
  bottom: -1px;
  font-size: 65%;
}

:root {
  --en-font1: 6vw;
  --en-font2: 2.3vw;
  --en-font3: 1.2vw;
  --en-font4: 1.25em;
  --kr-font1: 5.8vw;
  --kr-font2: 2vw;
  --kr-font3: 1.2vw;
  --kr-font4: 1em;
  --m-en-font1: 5em;
  --m-en-font2: 2.4em;
  --m-en-font3: 1.3em;
  --m-en-font4: 1.25em;
  --m-kr-font1: 5em;
  --m-kr-font2: 2.45em;
  --m-kr-font3: 1.35em;
  --m-kr-font4: 1.3em;
}

.en-font1 {
  font-size: var(--en-font1);
  line-height: 1.1;
}
.en-font2 {
  font-size: var(--en-font2);
  line-height: 1.1;
}
.en-font2-d {
  font-size: var(--en-font2);
  line-height: 1.1;
}
.en-font3 {
  font-size: var(--en-font3);
  line-height: 1.4;
}
.en-font4 {
  font-size: var(--en-font4);
  line-height: 1.4;
}
.kr-font1 {
  font-size: var(--kr-font1);
}
.kr-font2 {
  font-size: var(--kr-font2);
  line-height: 1.4;
}
.kr-font2-d {
  font-size: var(--kr-font2);
  line-height: 1.4;
}
.kr-font3 {
  font-size: var(--kr-font3);
  line-height: 1.5;
}
.kr-font4 {
  font-size: var(--kr-font4);
}

.spacing1 {
  letter-spacing: 1rem !important;
}

.pretendard {
  font-family: 'Pretendard Variable', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.BigCaslonRegular {
  font-family: big-caslon-fb, serif;
  font-weight: 400;
  font-style: normal;
}

.BigCaslonItalic {
  font-family: big-caslon-fb, serif;
  font-weight: 400;
  font-style: italic;
}

.contact-num {
  font-family: big-caslon-fb, serif;
  font-weight: 400;
  font-style: italic;
}

.paragraph {
  margin-bottom: .78rem!important;
}


.swash {
  font-feature-settings: 'swsh' on;
}

.container {
  display: flex;
}

.container header {
  flex: auto;
  padding: 0 1rem;
  position: fixed;
  width: calc(32% - 2rem);
  height: 100vh;
  overflow-y: scroll;
}

.container section {
  flex: auto;
  padding: 110px 1rem 0;
  max-width: 66%;
  margin-left: calc(34% - 2rem);
}

.swiper {
  width: 100%;
  /*height: calc(100vh - 30px);*/
}

.work-slide {
  height: calc(100vh - 30px);
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background: #F0F0EB !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 36px !important;
}

.swiper-slide img {
  width: 100%;
}

.swiper-slide > div {
  height: calc(66vw * 9 / 16);
  width: 100%;
}

.swiper-slide > div > iframe {
  height: 100%;
  width: 100%;
}

.video-swiper {
  background-color: #efefef;
}

.video-swiper .swiper-slide {
  max-height: 56.25vw;
}
/*.video-swiper .swiper-slide > div > div {*/
/*  z-index: -10;*/
/*}*/

.work-title > p > span {
  display: inline;
}

.work-title > p > .ml-en,
.work-title > p > .ml-num {
  font-size: var(--en-font2);
  letter-spacing: -0.008em;
  font-style: italic;
}

.work-title > p > .ml-ko {
  letter-spacing: -0.015em;
  font-size: var(--kr-font2);
}

.work-title > p > .ml-space {
  letter-spacing: 0.25em;
}
.work-title > p > .ml-punct{
  font-size: var(--en-font2);
  font-weight: 400;
  font-family: big-caslon-fb,serif;
}

#contact-address > p > .ml-en {
  font-size: var(--en-font2);
  font-family: big-caslon-fb,serif;
  font-weight: 400;
  font-style: italic;
  line-height: 1.1;
}

#contact-address > p > .ml-ko{
  font-size: var(--kr-font2);
  line-height: 1.4;
}

#contact-address > p > .ml-num{
  font-weight: 400;
  font-style: italic;
  font-family: big-caslon-fb,serif;
  line-height: 1.1;
}

#contact-address > p > .ml-punct{
  font-weight: 400;
  font-style: italic;
  font-family: big-caslon-fb,serif;
  line-height: 1.1;
}

.about-kr > .ml-en {
  font-style: italic;
  font-family: big-caslon-fb,serif;
  font-weight: 400;
}

/*.vertical-carousel > .swiper-wrapper {*/
/*  height:  100%;*/
/*}*/

@media screen and (max-width: 768px) {
  .en-font1 {
    font-size: var(--m-en-font1);
  }
  .en-font2 {
    font-size: var(--m-en-font2);
  }
  .en-font2-d {
    font-size: var(--m-en-font3);
  }
  .en-font3 {
    font-size: var(--m-en-font3);
  }
  .en-font4 {
    font-size: var(--m-en-font4);
  }
  .kr-font1 {
    font-size: var(--m-kr-font1);
  }
  .kr-font2 {
    font-size: var(--m-kr-font2);
  }
  .kr-font2-d {
    font-size: var(--m-kr-font3);
  }
  .kr-font3 {
    font-size: var(--m-kr-font3);
    line-height: 1.4;
  }
  .kr-font4 {
    font-size: var(--m-kr-font4);
  }

  .work-title > p > .ml-en,
  .work-title > p > .ml-num {
    font-size: var(--m-en-font2);
  }
  .work-title > p > .ml-ko {
    font-size: var(--m-kr-font2);
    letter-spacing: -0.0025em;
  }
  .work-title > p > .ml-punct{
    font-size: var(--m-en-font2);
  }
  #contact-address > p > .ml-en {
    font-size: 1em;
  }
  #contact-address > p > .ml-ko{
    font-size: 1em;
  }

  .container {
    display: block;
  }

  .container header,
  .container section {
    max-width: 100%;
    width: 100%;
  }
  .container header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 11;
    padding: 0;
    height: 110px;
    overflow: unset;
  }

  .container section {
    padding: 110px 0 5rem;
    margin-left: 0;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 24px !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0 !important;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0 !important;
  }
  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='tel'],
  textarea {
    width: 100%;
    font-size: var(--m-en-font3);
  }

  .work-slide > .swiper-wrapper > .swiper-slide {
    height: 550px;
  }
  .swiper-slide > div {
    height: fit-content;
    width: 100%;
  }

  .video-swiper .swiper-slide {
    min-height: 56.25vw;
  }

  .video-swiper .swiper-slide > div > div {
    z-index: 10;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.scrollDownAni {
  animation: scroll-down 2s linear infinite;
}
@keyframes scroll-down {
  0% {
    top: calc(80vh + 1rem);
  }
  50% {
    top: calc(80vh + 2rem);
  }
  100% {
    top: calc(80vh + 1rem);
  }
}