nav ul .mainColor1:hover span,
nav ul .mainColor1 .active span {
  color: #ff823c;
}
nav ul .mainColor2:hover span,
nav ul .mainColor2 .active span {
  color: #ff3cb4;
}
nav ul .subColor1:hover span,
nav ul .subColor1 .active span {
  color: #6969aa;
}


nav ul .mainColor2 ul li h1 {
  font-size: var(--kr-font2);
  border-top: 2px solid #000;
  cursor: pointer;
  padding: 0.25rem 0;
  margin-bottom: 0 !important;
}
nav ul .mainColor2 ul li:hover h1 {
  color: #ff3cb4;
}